import rest from "@/utils/rest";

export default {
  accountLogin(param) {
    return rest.post(`account/login`, param);
  },
  addAccount(param) {
    return rest.post(`account/addInstitutionAccount`, param);
  },
  modifyPassword(param) {
    return rest.post(`account/modifyPassword`, param);
  },
  accountList(param) {
    return rest.post(`account/list`, param);
  },
  accountDetail(accountId) {
    return rest.get(`account/detail/${accountId}`);
  },
  modifyAccount(param) {
    return rest.post(`account/modifyAccount`, param);
  },
  deleteAccount(accountId) {
    return rest.delete2(`account/delete/${accountId}`);
  },

  examList(param) {
    return rest.post(`exam/list`, param);
  },
  addDoctorOpinion(param) {
    return rest.post(`exam/addDoctorOpinion`, param);
  },
  getExamDetails(param) {
    return rest.get(`exam/getExamDetails`, param);
  },
};
